// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
});

$(document).ready(function(){
  var owl = $(".owl-carousel.owl-carousel1").owlCarousel({
    loop: true,
    margin: 15,
    nav: true,
    smartSpeed: 900,
    center: true,
    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
      },
      640: {
        items: 1,
        stagePadding: 0,
      },
      768: {
        items: 1,
        stagePadding: 0,
      },
      992: {
        items: 1,
        stagePadding: 0,
      },
      1200: {
        items: 1,
        stagePadding: 250,
      },
      1600: {
        items: 1,
        stagePadding: 300,
      }
    },
    stagePadding: 250,
  });

  $('#owl-prev').click(function() {
    owl.trigger('next.owl.carousel');
  });

  $('#owl-next').click(function() {
    owl.trigger('prev.owl.carousel', [300]);
  });

  var owl2 = $(".owl-carousel.owl-carousel2").owlCarousel({
    loop: true,
    nav: false,
    smartSpeed: 900,
    center: true,
    responsive: {
      0: {
        items: 1
      },
      640: {
        items: 1
      },
      768: {
        items: 3
      },
      992: {
        items: 3
      },
      1200: {
        items: 3,
      },
      1600: {
        items: 4,
      }
    },
  });

  $('#owl-prev2').click(function() {
    owl2.trigger('next.owl.carousel');
  });

  $('#owl-next2').click(function() {
    owl2.trigger('prev.owl.carousel', [300]);
  });
});
